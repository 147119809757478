import React from 'react'
import clsx from 'clsx'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { Collapse, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { Group, Language, Person, Translate, Work } from '@material-ui/icons'
import { canSeeAdmin, canEditLanguages, canEditProjects, canEditUsers } from '../services/PermissionsService'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        menuButton: {
            marginRight: 36
        },
        hide: {
            display: 'none'
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap'
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
            })
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1
            }
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3)
        },
        nested: {
            paddingLeft: theme.spacing(4)
        },
        icon: {
            color: '#00B482'
        }
    })
)

const translationNavigationItem = {
    path: '/',
    label: 'Translations',
    icon: <Translate/>
}

const adminNavigationItem = {
    path: '/admin',
    label: 'Admin',
    icon: <Person/>
}


const location = window.location.pathname

export default () => {
    const adminSubmenuItems = [
        ...canEditUsers() ? [{
            path: '/admin/users',
            label: 'Users',
            icon: <Group/>
        }] : [],
        ...canEditProjects() ? [{
            path: '/admin/projects',
            label: 'Projects',
            icon: <Work/>
        }] : [],
        ...canEditLanguages() ? [{
            path: '/admin/languages',
            label: 'Languages',
            icon: <Language/>
        }] : []
    ]
    const classes = useStyles()
    const theme = useTheme()
    const [isDrawerOpen, setDraweOpen] = React.useState(false)

    const isItemColored = (path: Boolean) => {
        if (path) {
            return classes.icon
        } else {
            return ''
        }
    }

    return (
        <div className={classes.root} style={{ zIndex: 1 }}>
            <CssBaseline/>
            <Drawer
                onMouseEnter={() => setDraweOpen(true)}
                onMouseLeave={() => setDraweOpen(false)}
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: isDrawerOpen,
                    [classes.drawerClose]: !isDrawerOpen
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: isDrawerOpen,
                        [classes.drawerClose]: !isDrawerOpen
                    })
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </div>
                <Divider/>
                <List>
                    <ListItem key={1} button onClick={() => window.location.assign(`${translationNavigationItem.path}`)}>
                        <ListItemIcon className={isItemColored(location === translationNavigationItem.path || location.includes('editTranslation'))}> {translationNavigationItem.icon}</ListItemIcon>
                        <ListItemText className={isItemColored(location === translationNavigationItem.path || location.includes('editTranslation'))} primary={translationNavigationItem.label}/>
                    </ListItem>

                    {canSeeAdmin() && <ListItem key={2} button>
                        <ListItemIcon className={isItemColored(location.includes(adminNavigationItem.path))}> {adminNavigationItem.icon}</ListItemIcon>
                        <ListItemText className={isItemColored(location.includes(adminNavigationItem.path))} primary={adminNavigationItem.label}/>
                    </ListItem>}

                    <Collapse in={isDrawerOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {adminSubmenuItems.map((item, index) => (
                                <ListItem key={index} button className={classes.nested} selected={location === item.path} onClick={() => window.location.assign(`${item.path}`)}>
                                    <ListItemIcon className={isItemColored(location === item.path)}> {item.icon} </ListItemIcon>
                                    <ListItemText primary={item.label}/>
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>
                </List>
            </Drawer>
        </div>
    )
}