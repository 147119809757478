import { newObjectId, ProjectFile } from '@aag-translations/common/lib'
import axios from 'axios'
import getPath from '../utils/Routes'

export async function getProjectFile(projectId: number, projectFileId: number, keys = false): Promise<ProjectFile> {
    return (await axios.get(`${getPath()}?method=projectFile&projectFileId=${projectFileId}&projectId=${projectId}&keys=${keys}`)).data
}

export async function saveProjectFile(projectId: number, projectFile: ProjectFile): Promise<any> {
    if (projectFile.translationKeys) {
        projectFile.totalKeys = Object.values(projectFile.translationKeys).length
    }
    const saveResult = (await axios.post(`${getPath()}?method=saveProjectFile&projectId=${projectId}`, projectFile)).data
    if (projectFile.id === newObjectId) {
        await saveProjectFile(projectId, await getProjectFile(projectId, saveResult))
    }
    return saveResult
}

let lastSetFileKeyBlock: Promise<unknown> | null = null

export async function setFileKeyBlock(projectId: number, projectFileId: number, projectFileKeyId: number, state: boolean) {
    if (!lastSetFileKeyBlock) {
        lastSetFileKeyBlock = (axios.post(`${getPath()}?method=setFileKeyBlock&projectFileId=${projectFileId}&projectId=${projectId}&projectFileKeyId=${projectFileKeyId}&state=${+state}`, {}))
    } else {
        lastSetFileKeyBlock.then(() => {
            axios.post(`${getPath()}?method=setFileKeyBlock&projectFileId=${projectFileId}&projectId=${projectId}&projectFileKeyId=${projectFileKeyId}&state=${+state}`, {})
        })
    }
}

export async function deleteFile(projectId: number, fileId: number) {
    return (await axios.post(`${getPath()}?method=deleteProjectFile&projectId=${projectId}&fileId=${fileId}`, {})).data

}