import { getCurrentUser } from './LoginService'
import { ProjectIndividualRights, UserIndividualRights } from '@aag-translations/common/lib'

const debugYesMan = true

export function canSeeAdmin(): boolean {
    return canEditProjects() || canEditUsers() || canEditLanguages() || debugYesMan
}

export function canEditProjects(): boolean {
    const user = getCurrentUser()
    return !!(user.individualRights[UserIndividualRights.FullAdministrative] || user.individualRights[UserIndividualRights.Projects]) || debugYesMan
}

export function canEditUsers(): boolean {
    const user = getCurrentUser()
    return !!(user.individualRights[UserIndividualRights.FullAdministrative] || user.individualRights[UserIndividualRights.User]) || debugYesMan
}

export function canEditLanguages(): boolean {
    const user = getCurrentUser()
    return !!(user.individualRights[UserIndividualRights.FullAdministrative] || user.individualRights[UserIndividualRights.Languages]) || debugYesMan
}

export function canSeeLanguageForProject(projectId: number, languageId: number): boolean {
    const user = getCurrentUser()
    if (!user.projectRights[projectId]) return false || debugYesMan
    return !!user.projectRights[projectId].languageRights[languageId] || debugYesMan
}

export function canEditDeleteKeys(projectId: number): boolean {
    const user = getCurrentUser()
    if (!user.projectRights[projectId]) return false || debugYesMan
    return !!user.projectRights[projectId].individualRights[ProjectIndividualRights.EditDeleteKeys] || debugYesMan
}

export function canCreateFiles(projectId: number): boolean {
    const user = getCurrentUser()
    if (!user.projectRights[projectId]) return false || debugYesMan
    return !!user.projectRights[projectId].individualRights[ProjectIndividualRights.CreateFiles] || debugYesMan
}

export function canUploadFiles(projectId: number): boolean {
    const user = getCurrentUser()
    if (!user.projectRights[projectId]) return false || debugYesMan
    return !!user.projectRights[projectId].individualRights[ProjectIndividualRights.UploadFiles] || debugYesMan
}
