import axios from 'axios'
import { User } from '@aag-translations/common/lib'
import getPath from '../utils/Routes'

export async function getAllUsers(): Promise<Array<User>> {
    return (await axios.get(`${getPath()}?method=allUsers`)).data
}

export async function getUser(): Promise<User> {
    return (await axios.get(`${getPath()}?method=allUsers`)).data
}

export async function saveUser(user: User): Promise<void> {
    return (await axios.post(`${getPath()}?method=saveUser`, user)).data
}

export async function deleteUser(userId: number): Promise<void> {
    return (await axios.post(`${getPath()}?method=deleteUser&userId=${userId}`)).data
}
