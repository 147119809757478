import { LogLevel } from './utils/logger'

const common = {
    sso: {
        ENABLED: true,
        COOKIE_NAME: 'TR_AUTH_SAML_TOKEN',
        IDP_ENDPOINT: 'https://auth.aesculap.extranet.bbraun.com/AuthenticationService/service/saml/login/getSAMLLogin'
    },
    app: {
        URL: ''
    },
    apiGateway: {
        URL: ''
    },
    logLevel: LogLevel.DEBUG
}

const dev = {
    app: {
        URL: 'http://local.translate.aesculap.extranet.bbraun.com:59089'
    },
    apiGateway: {
        URL: ''
    }
}

const test = {
    app: {
        URL: 'https://qas.translate.aesculap.extranet.bbraun.com'
    },
    apiGateway: {
        URL: ''
    }
}

const prod = {
    app: {
        URL: 'https://translate.aesculap.extranet.bbraun.com'
    },
    apiGateway: {
        URL: ''
    }
}

// eslint-disable-next-line no-nested-ternary
const config = process.env.REACT_APP_STAGE === 'PROD' ? prod : process.env.REACT_APP_STAGE === 'TEST' ? test : dev

export default {
    ...common,
    ...config
}
