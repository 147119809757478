export enum LogLevel {
    'ERROR',
    'WARN',
    'INFO',
    'DEBUG',
    'TRACE'
}

export const getLogger = (logLevel: LogLevel) => {
    const noop = (...data: any[]): void => {}
    const trace = logLevel >= LogLevel.TRACE ? console.trace : noop
    const debug = logLevel >= LogLevel.DEBUG ? console.debug : noop
    const info = logLevel >= LogLevel.INFO ? console.info : noop
    const warn = logLevel >= LogLevel.WARN ? console.warn : noop
    const error = logLevel >= LogLevel.ERROR ? console.error : noop
    return {
        trace,
        debug,
        info,
        warn,
        error
    }
}
