import { TranslationFile, TranslationKey } from '@aag-translations/common/lib'
import axios from 'axios'
import getPath from '../utils/Routes'

export async function getTranslationFile(projectFileId: number, languageId: number, keys = false): Promise<TranslationFile> {
    return (await axios.get(`${getPath()}?method=translationFile&projectFileId=${projectFileId}&translationFileId=${languageId}&keys=${keys}`)).data
}

export async function saveTranslationFile(projectFileId: number, translationFile: TranslationFile): Promise<TranslationFile> {
    translationFile.doneCount = Object.values(translationFile.translationsKeys).reduce<number>((acc, current) => {
        if (current.isDone) acc++
        return acc
    }, 0)
    return axios.post(`${getPath()}?method=saveTranslationFile&projectFileId=${projectFileId}`, translationFile)
}

export async function clearTranslationFile(projectFileId: number, languageId: number): Promise<TranslationFile> {
    return axios.post(`${getPath()}?method=clearTranslationFile&projectFileId=${projectFileId}&translationFileId=${languageId}`, {})
}

export async function saveTranslationFileKey(translationKey: TranslationKey, projectFileId: number, translationFileId: number) {
    return axios.post(`${getPath()}?method=saveTranslationFileKey&projectFileId=${projectFileId}&translationFileId=${translationFileId}`, translationKey)
}
