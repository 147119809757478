import axios from 'axios'
import getPath from '../utils/Routes'

export async function saveDocument(file: string, path: string) {
    return (await axios.post(`${getPath()}?method=saveDocument&documentName=${path}`, JSON.stringify(file))).data
}

export async function getDocument(path: string) {
    return (await axios.get(`${getPath()}?method=getDocument&documentName=${path}`)).data
}

export function downloadBase64File(contentBase64: string, fileName: string) {
    const linkSource = `data:application/pdf;base64,${contentBase64}`
    const downloadLink = document.createElement('a')
    document.body.appendChild(downloadLink)

    downloadLink.href = linkSource
    downloadLink.target = '_self'
    downloadLink.download = fileName
    downloadLink.click()
}