import React, { useState } from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { createProject, Project, User } from '@aag-translations/common/lib'
import { DeleteOutlined, EditOutlined } from '@material-ui/icons'
import styled from 'styled-components'
import { Button } from '@progress/kendo-react-buttons'
import EditProjectModal from '../components/common/EditProjectModal'
import { deleteProject, getAllProjects } from '../services/ProjectService'
import { canEditProjects } from '../services/PermissionsService'
import { getAllUsers } from '../services/UserService'

const Wrapper = styled.div`
    max-width: 50%;
`

function getUserCount(users: Array<User>, projectId: number) {
    const filteredUser = users.filter((user: User) => {
        return user.projectRights[projectId] != null
    })
    return filteredUser.length
}

function Row(props: { project: Project }) {
    const { project } = props
    const [modalIsOpen, setIsOpen] = React.useState<boolean>(false)
    const [users, setUsers] = useState<Array<User>>([])
    if (users.length === 0) getAllUsers().then(setUsers)

    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row">
                    {project.projectName}
                </TableCell>
                <TableCell align="right">{getUserCount(users, project.id)}</TableCell>
                <TableCell align="right">{Object.keys(project.languages).length}</TableCell>
                <TableCell align="right">
                    <EditProjectModal project={project} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} languages={project.languages} />
                    <EditOutlined
                        style={{ cursor: 'pointer', marginRight: '12px' }}
                        onClick={() => {
                            setIsOpen(true)
                        }}
                    />
                    <DeleteOutlined
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            deleteProject(project.id).then(() => {
                                window.location.reload()
                            })
                        }}
                    />
                </TableCell>
            </TableRow>
        </>
    )
}

export default function Projects(): JSX.Element {
    const [projects, setProjects] = useState<Array<Project>>([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const project = createProject()
    if (!canEditProjects()) {
        return <h2>You dont have permission to edit projects</h2>
    }
    if (projects.length === 0) getAllProjects().then(setProjects)
    return (
        <Wrapper>
            <Button onClick={() => setIsModalOpen(!isModalOpen)}>New project</Button>
            <EditProjectModal project={null} modalIsOpen={isModalOpen} setIsOpen={setIsModalOpen} languages={project.languages} />
            <Table aria-label="collapsible table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Application</TableCell>
                        <TableCell align="right">User</TableCell>
                        <TableCell align="right">Languages</TableCell>
                        <TableCell align="right" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {projects.map((item: Project) => (
                        <Row key={item.id} project={item} />
                    ))}
                </TableBody>
            </Table>
        </Wrapper>
    )
}
