import React from 'react'
import ReactDOM from 'react-dom'
import 'react-toastify/dist/ReactToastify.css'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import './bbraun.css'
import './index.css'
import { CookiesProvider } from 'react-cookie'
import { getItem } from './utils/localStorage'
import App from './App'

const renderApplication = () => {
    ReactDOM.render(
        <CookiesProvider>
            <App />
        </CookiesProvider>,
        document.getElementById('root')
    )
}

const token = getItem('token')
if (token) {
    Promise.resolve()
        // validate the session here
        .then(renderApplication)
        .catch(() => {
            ReactDOM.render(
                <div>
                    <h1>An error occured</h1>
                </div>,
                document.getElementById('root')
            )
        })
} else {
    renderApplication()
}
