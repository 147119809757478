import React from 'react'
import styled from 'styled-components'
import { AppBar, Toolbar, Typography } from '@material-ui/core'
import { User } from '@aag-translations/common/lib'

export default (props: { user: User }) => {
    const { user } = props
    return (
        <StyledAppBar position="static">
            <StyledToolbar>
                <Typography variant="h6" color="inherit">Aesculap Translations</Typography>
                <Typography variant="body2">{user.firstName} {user.lastName}</Typography>
            </StyledToolbar>
        </StyledAppBar>
    )
};

const StyledAppBar = styled(AppBar)`
    display: flex;
    flex: 0 0 auto;
`

const StyledToolbar = styled(Toolbar)`
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
