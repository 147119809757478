import React from 'react'
import { Button } from '@progress/kendo-react-buttons'
import styled from 'styled-components'

interface ButtonProps {
    onCancel: any,
    onSave: any,
}

export default (props: ButtonProps) => {
    return (
        <ButtonSection>
            <Button style={{marginRight: '15px'}} onClick={props.onCancel}>Cancel</Button>
            <Button primary onClick={props.onSave}>Save</Button>
        </ButtonSection>
    )
}

const ButtonSection = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
`