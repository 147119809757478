import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import axios from 'axios'
import { getLogger } from '../utils/logger'
import config from '../config'
import { getUserFromToken } from '../services/LoginService'

const logger = getLogger(config.logLevel)

export default (props: { setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>> }): JSX.Element => {
    const { setIsLoggedIn } = props
    const [cookies] = useCookies([config.sso.COOKIE_NAME])
    const samlCookie = cookies[config.sso.COOKIE_NAME]
    const [error, setError] = useState(false)
    if (samlCookie === undefined && config.sso.ENABLED) {
        useEffect(() => {
            const { CancelToken } = axios
            const source = CancelToken.source()
            const loadData = () => {
                axios({
                    method: 'GET',
                    url: `${config.sso.IDP_ENDPOINT}?returnToOnSuccess=${config.app.URL}`
                })
                    .then((response) => {
                        return response.data
                    })
                    .then(({ ssourlwithAuthNRequest }) => {
                        window.location.href = ssourlwithAuthNRequest
                    })
                    .catch((err) => {
                        if (axios.isCancel(err)) {
                            logger.info('IDP re-Authorisation canceled')
                        } else {
                            logger.error('IDP-Authorisation is not correct')
                            setError(true)
                        }
                    })
            }
            loadData()
            return () => {
                source.cancel()
            }
        }, [])
    } else if (config.sso.ENABLED) {
        getUserFromToken(samlCookie)
            .then((response) => {
                setIsLoggedIn(true)
            })
            .catch((e) => {
                logger.error('Authentication is not success. Error: ', e)
                setError(true)
            })
    }
    return error ? <h2>You could not be logged in, please call support.</h2> : <></>
}
