export default function getPath() {
    switch (process.env.REACT_APP_STAGE) {
        case 'DEV': {
            return 'https://eg9aptp9p3.execute-api.eu-central-1.amazonaws.com/prod'
            break
        }
        case 'TEST': {
             return 'https://eg9aptp9p3.execute-api.eu-central-1.amazonaws.com/prod'
            break
        }
        case 'PROD': {
             return 'https://da02fotzo9.execute-api.eu-central-1.amazonaws.com/prod'
            break
        }
        default: {
            break
        }
    }
    return 'https://da02fotzo9.execute-api.eu-central-1.amazonaws.com/prod'
}
