import { createNewUser, newObjectId, User } from '@aag-translations/common/lib'
import axios from '../utils/axios'
import getPath from '../utils/Routes'
import { LogLevel, getLogger } from '../utils/logger'
import config from '../config'

const logger = getLogger(config.logLevel)

let currentUser: User = createNewUser()
logger.debug('Current User: ', currentUser)

export function getIsLoggedIn(): boolean {
    return currentUser.id !== newObjectId
}

export function getCurrentUser(): User {
    return currentUser
}

export async function getUserFromToken(token: string): Promise<User> {
    currentUser = (await axios.get(`${getPath()}?method=getUserFromToken&cookie=${token}`)).data
    logger.debug('Current User from token (backend): ', currentUser)
    return currentUser
}
