import './modernizr'

const checkBrowserSupport = () => {
    // eslint-disable-line-next-line
    if (!window.Modernizr.localstorage) throw new Error('LocalStorage not supported by browser')
}

export const setItem = (key, value) => {
    checkBrowserSupport()

    window.localStorage.setItem(key, JSON.stringify(value))
    return true
}

export const getItem = (key) => {
    checkBrowserSupport()

    try {
        return JSON.parse(window.localStorage.getItem(key))
    } catch (error) {
        return ''
    }
}

export const removeItem = (key) => {
    checkBrowserSupport()

    try {
        window.localStorage.removeItem(key)
    } catch (error) {
        throw error
    }
}

export const exists = (key) => {
    checkBrowserSupport()

    try {
        const item = getItem(key)
        return !!item
    } catch (e) {
        return false
    }
}
