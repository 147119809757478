import React, { useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core'
import styled from 'styled-components'
import { ToastContainer } from './components/common/Toast'
import { Header, Sidebar } from './layout'
import { BBraunTheme } from './theme'
import OverviewTable from './components/common/OverviewTable'
import { EditTranslation } from './components/common/EditTranslation'
import Users from './layout/Users'
import Projects from './layout/Projects'
import { Languages } from './layout/Languages'
import { getIsLoggedIn, getCurrentUser } from './services/LoginService'
import Login from './layout/Login'
import { getLogger } from './utils/logger'
import config from './config'

const logger = getLogger(config.logLevel)

const Wrapper = styled.div`
    display: flex;
`

const Content = styled.div`
    display: block;
    flex: 1;
    margin: 20px 40px;
`

export default (): JSX.Element => {
    logger.debug(`App stage: ${process.env.REACT_APP_STAGE}`)
    const [isLoggedIn, setIsLoggedIn] = useState(getIsLoggedIn())
    logger.debug(`User is logged: ${isLoggedIn}`)
    if (!isLoggedIn) {
        return (
            <>
                <ToastContainer />
                <Login setIsLoggedIn={setIsLoggedIn} />
            </>
        )
    }
    return (
        isLoggedIn && (
            <>
                <ToastContainer />
                <BrowserRouter>
                    <ThemeProvider theme={BBraunTheme}>
                        <Header user={getCurrentUser()} />
                        <Wrapper>
                            <Sidebar />
                            <Content>
                                <Routes>
                                    <Route path="/editTranslation">
                                        <EditTranslation />
                                    </Route>
                                    <Route path="/admin/*">
                                        <Route path="users">
                                            <Users />
                                        </Route>
                                        <Route path="projects">
                                            <Projects />
                                        </Route>
                                        <Route path="languages">
                                            <Languages />
                                        </Route>
                                    </Route>
                                    <Route path="/">
                                        <OverviewTable />
                                    </Route>
                                </Routes>
                            </Content>
                        </Wrapper>
                    </ThemeProvider>
                </BrowserRouter>
            </>
        )
    )
}
