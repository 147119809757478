import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core'
import { Person, Translate } from '@material-ui/icons'


const navigationItems = [
    {
        path: '',
        label: 'Translations',
        icon: <Translate/>
    },
    {
        path: 'admin',
        label: 'Admin',
        icon: <Person/>
    }
]

export default () => {
    const location = window.location
    const pathElements = location.pathname.split('/')
    let idx = 0
    if (pathElements.length > 1) {
        const main = pathElements[1]
        idx = navigationItems.findIndex(entry => entry.path === main)
    }
    const [currentValue, setCurrentValue] = useState(idx > 0 ? idx : 0)

    useEffect(() => {
        const main = pathElements[1]
        idx = navigationItems.findIndex(entry => entry.path === main)
        setCurrentValue(idx)
    }, [location])

    return <Footer>
        <StyledBottomNavigation
            value={currentValue}
            onChange={(event, newValue) => {
                setCurrentValue(newValue)
            }}
            showLabels>
            {navigationItems.map((item, index) => (
                <BottomNavigationAction key={`nav_${index}`} label={item.label} icon={item.icon} onClick={() => window.location.assign(`/${item.path}`)} />
            ))}
        </StyledBottomNavigation>
    </Footer>
}

const Footer = styled.div`
    display: flex;
    flex: 0 0 50px;
    background-color: #f2f2f2;
`

const StyledBottomNavigation = styled(BottomNavigation)`
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #f2f2f2 !important;    
`
