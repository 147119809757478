import React, { useState } from 'react'
import { createProject, createProjectFile, createTranslationFile, Language, Project, ProjectFile, ProjectFileKey, TranslationFile, TranslationKey } from '@aag-translations/common/lib'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import styled from 'styled-components'
import { getProjectFile, setFileKeyBlock } from '../../services/ProjectFileService'
import { getTranslationFile, saveTranslationFile, saveTranslationFileKey } from '../../services/TranslationFileService'
import { getProject } from '../../services/ProjectService'
import { Label } from '@progress/kendo-react-labels'
import { Input } from '@material-ui/core'
import { Checkbox } from '@progress/kendo-react-inputs'
import { Button } from '@progress/kendo-react-buttons'
import { getAllLanguages } from '../../services/LanguageService'
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined'
import { parseAndSaveProjectFile } from '../../services/LanguageFileParser'
import { exportLanguageFile } from '../../services/LanguageFileExporter'
import fileDownload from 'js-file-download'
import { languageFileType } from '@aag-translations/common/lib'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import { canEditDeleteKeys } from '../../services/PermissionsService'

const Wrapper = styled.div`
    width: 50%;
`

const SpecialButton = styled(Button)`
    margin-right: 15px;
`

function Row(props: { projectId: number, projectFileKey: ProjectFileKey, translationKey: TranslationKey, defaultLanguageKey: TranslationKey, projectFileId: number, translationFileId: number }) {
    const { projectId, projectFileKey, translationKey, defaultLanguageKey, projectFileId, translationFileId } = props
    const [blocked, setBlocked] = useState(projectFileKey.blocked)
    if (!translationKey || !defaultLanguageKey) {
        return <></>
    }
    let customStyle: Record<string, string> = {}
    if (projectFileKey.blocked) {
        customStyle['background'] = '#F6F6F6'
    }

    function onSetFileKeyBlock(value: boolean) {
        setFileKeyBlock(projectId, projectFileId, projectFileKey.id, value)
        projectFileKey.blocked = value
        setBlocked(value)
    }

    return (
        <TableRow key={projectFileKey.id} style={customStyle}>
            <TableCell>{projectFileKey.keyName}</TableCell>
            <TableCell>{defaultLanguageKey.translatedValue}</TableCell>
            <TableCell>
                {blocked ? (
                    translationKey.translatedValue
                ) : (
                    <Input
                        defaultValue={translationKey.translatedValue}
                        onChange={(event) => {
                            translationKey.isDone = true
                            translationKey.translatedValue = event.target.value
                        }}
                    />
                )}
            </TableCell>
            <TableCell>
                {
                    canEditDeleteKeys(projectId)?blocked ?
                        <LockIcon onClick={() => onSetFileKeyBlock(false)}/> :
                        <LockOpenIcon onClick={() => onSetFileKeyBlock(true)}/>:<></>
                }
                <SaveOutlinedIcon onClick={() => {
                    saveTranslationFileKey(translationKey, projectFileId, translationFileId)
                }}/>
            </TableCell>
        </TableRow>
    )
}

export function EditTranslation() {
    let searcher = new URLSearchParams(window.location.search)
    let projectId = parseInt(searcher.get('projectId') as string)
    let projectFileId = parseInt(searcher.get('projectFileId') as string)
    let languageId = parseInt(searcher.get('languageId') as string)
    const [loaded, setLoaded] = useState<{
        project: Project,
        projectFile: ProjectFile
        translationFile: TranslationFile,
        defaultTranslationFile: TranslationFile
    }>
    ({
        project: createProject(),
        projectFile: createProjectFile(),
        translationFile: createTranslationFile(),
        defaultTranslationFile: createTranslationFile()
    })

    const [done, setDone] = useState(false)
    const load = async function() {
        let modified = loaded
        modified.project = await getProject(projectId)
        modified.projectFile = await getProjectFile(projectId, projectFileId, true)
        modified.translationFile = await getTranslationFile(projectFileId, languageId, true)
        modified.defaultTranslationFile = await getTranslationFile(projectFileId, loaded.project.defaultLanguage, true)
        setDone(true)
        setLoaded(modified)
    }

    if (done) {
        return <EditTranslationDialog project={loaded.project} projectFile={loaded.projectFile} translationFile={loaded.translationFile} defaultLanguage={loaded.defaultTranslationFile}/>
    }
    load().then(r => {
    })
    return <></>

}

export function EditTranslationDialog(props: { project: Project, projectFile: ProjectFile; translationFile: TranslationFile; defaultLanguage: TranslationFile }) {
    const { project, projectFile, translationFile, defaultLanguage } = props
    const [languages, setLanguages] = useState<Record<number, Language>>({})
    const [onlyIncomplete, setOnlyIncomplete] = useState(false)
    if (Object.keys(languages).length === 0) getAllLanguages().then(setLanguages)
    projectFile.fileType = languageFileType.classificationLanguageFile

    async function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
        const files = event.target.files as FileList
        const file: File = files[0]
        await parseAndSaveProjectFile(file, project, projectFile, translationFile)
        window.location.reload()
    }

    async function handleFileUploadOverwrite(event: React.ChangeEvent<HTMLInputElement>) {
        const files = event.target.files as FileList
        const file: File = files[0]
        await parseAndSaveProjectFile(file, project, projectFile, translationFile, true)
        window.location.reload()
    }

    async function handleExport() {
        fileDownload(await exportLanguageFile(projectFile, translationFile, projectFile.fileType), `${projectFile.projectFileName}_${languages[translationFile.id].ISOCode}.properties`)
    }

    return (
        <Wrapper>
            <SpecialButton
                onClick={() => {
                    window.history.back()
                }}>
                Return
            </SpecialButton>
            <SpecialButton primary
                           onClick={() => saveTranslationFile(projectFile.id, translationFile)}>
                Save
            </SpecialButton>
            <input
                id='fileUpload'
                type='file'
                style={{ display: 'none' }}
                onChange={(event) => handleFileUpload(event)}
            />
            <SpecialButton style={{ padding: '0px' }}><label style={{ padding: '8px 16px', cursor: 'pointer' }} htmlFor="fileUpload">upload(Only add new)</label></SpecialButton>
            <input
                id='fileUploadOverwrite'
                type='file'
                style={{ display: 'none' }}
                onChange={(event) => handleFileUploadOverwrite(event)}
            />
            <SpecialButton style={{ padding: '0px' }}><label style={{ padding: '8px 16px', cursor: 'pointer' }} htmlFor="fileUploadOverwrite">upload(Overwrite old)</label></SpecialButton>
            <SpecialButton onClick={() => handleExport()} style={{ padding: '0px' }}><label style={{ padding: '8px 16px', cursor: 'pointer' }}>export</label></SpecialButton>
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                <Label style={{ marginRight: '8px' }}>Show only untranslated</Label>
                <Checkbox checked={onlyIncomplete} onChange={() => setOnlyIncomplete(!onlyIncomplete)}/>
            </span>

            <TableContainer style={{ marginTop: '20px' }} component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Key</TableCell>
                            <TableCell>Default language:{Object.keys(languages).length > 0 ? languages[defaultLanguage.id]?.displayName : ''}</TableCell>
                            <TableCell>Language to be translated:{Object.keys(languages).length > 0 ? languages[translationFile.id]?.displayName : ''}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.values(projectFile.translationKeys).filter((projectFileKey) => {
                            return !(translationFile.translationsKeys[projectFileKey.id].isDone && translationFile.translationsKeys[projectFileKey.id].translatedValue == null) || !onlyIncomplete
                        }).map((projectFileKey: ProjectFileKey) => {
                            return <Row projectFileKey={projectFileKey}
                                        projectId={project.id}
                                        translationFileId={translationFile.id}
                                        defaultLanguageKey={defaultLanguage.translationsKeys[projectFileKey.id]}
                                        projectFileId={projectFile.id}
                                        translationKey={translationFile.translationsKeys[projectFileKey.id]}
                            />
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Wrapper>)
}
