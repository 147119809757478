import React, { useState } from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { Language } from '@aag-translations/common/lib'
import styled from 'styled-components'
import Flag from 'react-world-flags'
import { EditOutlined, DeleteOutlined } from '@material-ui/icons'
import { Button } from '@progress/kendo-react-buttons'
import { EditLanguageModal } from '../components/common/EditLanguage'
import {deleteLanguage, getAllLanguages, mapIsoCode} from '../services/LanguageService'
import { canEditLanguages } from '../services/PermissionsService'
import {align} from "@progress/kendo-drawing";

const Wrapper = styled.div`
    max-width: 50%;
`

function Row(props: { language: Language; removeLanguage: Function }) {
    const { language, removeLanguage } = props
    const [modalIsOpen, setIsOpen] = React.useState<boolean>(false)

    return (
        <>
            <TableRow>
                {/*<TableCell component="th" scope="row">
                    {language.id}
                </TableCell>*/}
                <TableCell align="left">{language.displayName}</TableCell>
                <TableCell align="left">
                        <Flag code={mapIsoCode(language.ISOCode)} width="34" height="auto" />
                </TableCell>
                <TableCell align="right">
                    <EditLanguageModal language={language} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
                    <EditOutlined
                        style={{ cursor: 'pointer', marginRight: '12px' }}
                        onClick={() => {
                            setIsOpen(true)
                        }}
                    />
                    <DeleteOutlined
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            removeLanguage(language.id)
                        }}
                    />
                </TableCell>
            </TableRow>
        </>
    )
}

export function Languages() {
    const [languages, setLanguages] = useState<Record<number, Language>>({})
    const [isModalOpen, setIsModalOpen] = useState(false)
    if (!canEditLanguages()) {
        return <h2>You dont have permission to edit languages</h2>
    }
    if (Object.keys(languages).length === 0) getAllLanguages().then(setLanguages)

    function removeLanguage(id: number) {
        const localLanguages = { ...languages }
        delete localLanguages[id]
        setLanguages(localLanguages)
        deleteLanguage(id)
    }

    return (
        <Wrapper>
            <Button onClick={() => setIsModalOpen(!isModalOpen)}>New language</Button>
            <EditLanguageModal language={null} modalIsOpen={isModalOpen} setIsOpen={setIsModalOpen} />
            <Table aria-label="collapsible table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Language</TableCell>
                        <TableCell align="left">Flag</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.values(languages).map((language: Language) => (
                        <Row key={language.id} removeLanguage={removeLanguage} language={language} />
                    ))}
                </TableBody>
            </Table>
        </Wrapper>
    )
}
