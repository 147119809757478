import React from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { Language } from '@aag-translations/common/lib'
import { Dialog } from '@progress/kendo-react-dialogs'
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { getAllLanguages, saveLanguage } from '../../services/LanguageService'
import { GridWrapper, StyledPaper } from '../style/FormComponents'
import DialogActionButtons from '../style/DialogActionButtons'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 280px;
`

export function EditLanguageModal(props: { language: Language | null; modalIsOpen: boolean; setIsOpen: Function }) {
    let language: Language
    if (!props.language) {
        language = {
            ISOCode: '',
            displayName: '',
            id: -1
        }
    } else {
        language = props.language
    }
    const { modalIsOpen, setIsOpen } = props
    const [languages, setLanguages] = React.useState<Record<number, Language>>([])
    if (Object.keys(languages).length === 0) getAllLanguages().then(setLanguages)

    function closeModal(): void {
        setIsOpen(false)
    }

    function save(formData: any) {
        Object.keys(languages).map((key) => {
            let object = languages[parseInt(key)]
            if (object.id === language.id) {
                object.displayName = formData.displayName
                object.ISOCode = formData.ISOCode
            }
        })
        saveLanguage(formData).then(closeModal)
    }

    return (
        <div>
            {modalIsOpen && (
                <Dialog>
                    <GridWrapper>
                        <Container>
                            <Form
                                onSubmit={save}
                                initialValues={language}
                                render={(formRenderProps) => (
                                    <FormElement>
                                        <StyledPaper>
                                            <Field
                                                label='Name'
                                                type={'text'}
                                                name={'displayName'}
                                                component={TextField}
                                                style={{width: '100%', marginBottom: '10px'}}
                                            />
                                            <Field
                                                label='ISO Code'
                                                type={'text'}
                                                name={'ISOCode'}
                                                component={TextField}
                                                style={{width: '100%'}}
                                            />
                                        </StyledPaper>
                                        <DialogActionButtons onCancel={closeModal} onSave={formRenderProps.onSubmit}/>
                                    </FormElement>
                                )}/>
                        </Container>
                    </GridWrapper>
                </Dialog>)
            }
        </div>
    )
}
