import React, { Dispatch, SetStateAction } from 'react'
import { Dialog } from '@progress/kendo-react-dialogs'
import styled from 'styled-components'
import { FormControl, FormHelperText, TextField } from '@material-ui/core'
import { Language, Project } from '@aag-translations/common/lib'
import { Checkbox } from '@progress/kendo-react-inputs'
import { saveProject } from '../../services/ProjectService'
import { getAllLanguages } from '../../services/LanguageService'
import { GridWrapper, StyledPaper } from '../style/FormComponents'
import DialogButtons from '../style/DialogActionButtons'

const Container = styled.div`
    display: flex;
    flex-direction: column;
`
const FormControlWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    min-width: 300px;
`

const CheckboxSection = styled.div`
    margin-top: 5px;
    max-height: 300px;
    text-align: left;
    overflow-y: scroll;
    padding-right: 16px;
`

export default function EditProjectModal(props: { project: Project | null; modalIsOpen: boolean; setIsOpen: Dispatch<SetStateAction<boolean>>; languages: Record<number, Language> }) {
    const { modalIsOpen, setIsOpen, project: projectFromProps } = props
    const project = projectFromProps || {
        id: -1,
        projectName: '',
        projectFilesExpanded: [],
        defaultLanguage: -1,
        languages: {}
    }
    const [defaultLanguageId, setDefaultLanguageId] = React.useState<number>(project.defaultLanguage)
    const [languages, setLanguages] = React.useState<Record<number, Language>>([])
    if (Object.keys(languages).length === 0) getAllLanguages().then(setLanguages)
    // const defaultLanguageId = project.defaultLanguage
    // if (!props.project) {
    //    project = {
    //         id: -1,
    //         projectName: '',
    //         projectFilesExpanded: [],
    //         defaultLanguage: -1,
    //         languages: {}
    //     }
    // } else {
    //     project = props.project
    //     defaultLanguageId = project.defaultLanguage
    // }
    // const [languages, setLanguages] = React.useState<Record<number, Language>>([])
    function markProjectFilesAsModified() {
        project.projectFilesExpanded.forEach((projectFile) => {
            projectFile.modified = true
        })
    }

    function changeDefaultLanguage(id: number): void {
        markProjectFilesAsModified()
        project.defaultLanguage = id
        setDefaultLanguageId(id)
    }

    function changeLanguages(id: number): void {
        markProjectFilesAsModified()
        //if (!project.languages[id]) project.languages[id] = true
        //else delete project.languages[id]
    }

    function closeModal(): void {
        setIsOpen(false)
    }

    function changeProjectName(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
        project.projectName = event.target.value
    }

    function save() {
        saveProject(project).then(closeModal)
    }

    if (modalIsOpen) {
        return (
            <Dialog>
                <Container>
                    <GridWrapper>
                        <StyledPaper>
                            <TextField
                                id="standard-basic"
                                style={{ width: '100%' }}
                                label="Name"
                                defaultValue={project.projectName}
                                onChange={(value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                    changeProjectName(value)
                                }}
                            />
                        </StyledPaper>
                        <FormControlWrapper>
                            <FormControl>
                                <StyledPaper style={{ display: 'block', minWidth: '150px' }}>
                                    <FormHelperText>Active Languages</FormHelperText>
                                    <CheckboxSection>
                                        {Object.keys(languages).map((languageKey) => {
                                            const languageValue = languages[(languageKey as unknown) as number]
                                            return (
                                                <div key={languageKey} style={{ marginBottom: '10px' }}>
                                                    <Checkbox
                                                        key={languageValue.id}
                                                        defaultChecked={project.defaultLanguage === languageValue.id}
                                                        // defaultChecked={project.languages[languageValue.id]}
                                                        label={languageValue.displayName}
                                                        onChange={() => {
                                                            changeLanguages(languageValue.id)
                                                        }}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </CheckboxSection>
                                </StyledPaper>
                            </FormControl>
                            <FormControl style={{ marginLeft: '10px' }}>
                                <StyledPaper style={{ display: 'block', minWidth: '150px' }}>
                                    <FormHelperText>Default Language</FormHelperText>
                                    <CheckboxSection>
                                        {Object.keys(languages).map((languageKey) => {
                                            const languageValue = languages[(languageKey as unknown) as number]
                                            return (
                                                <div key={languageKey} style={{ marginBottom: '10px' }}>
                                                    <Checkbox
                                                        key={languageValue.id}
                                                        checked={languageValue.id === defaultLanguageId}
                                                        label={languageValue.displayName}
                                                        onChange={() => {
                                                            changeDefaultLanguage(languageValue.id)
                                                        }}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </CheckboxSection>
                                </StyledPaper>
                            </FormControl>
                        </FormControlWrapper>
                        <DialogButtons onSave={save} onCancel={closeModal} />
                    </GridWrapper>
                </Container>
            </Dialog>
        )
    }
    return <></>
}
