import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { DeleteOutlined, EditOutlined } from '@material-ui/icons'
import { Language, Project, ProjectFile, TranslationFile } from '@aag-translations/common/lib'
import { clearTranslationFile } from '../../services/TranslationFileService'
import { getAllLanguages, mapIsoCode } from '../../services/LanguageService'
import Flag from 'react-world-flags'
import { Button } from '@progress/kendo-react-buttons'
import { EditProjectFileModal } from './EditProjectFileModal'
import { AdministrateKeysModal } from './AdministrateKeysModal'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { canEditDeleteKeys, canSeeLanguageForProject } from '../../services/PermissionsService'
import { deleteFile } from '../../services/ProjectFileService'

const useStyles = makeStyles({
    table: {
        minWidth: 650
    }
})

function safePercent(value: number) {
    if (Number.isNaN(value) || !Number.isFinite(value)) {
        value = 0
    }
    value = Math.floor(value * 100)
    return `${value}%`
}

function Row(props: { translationFile: TranslationFile, languages: Record<number, Language>, projectFile: ProjectFile, onEdit: Function, project: Project }) {
    const { translationFile, languages, projectFile, onEdit, project } = props
    const [doneCount, setDoneCount] = React.useState(translationFile.doneCount)
    return <TableRow key={translationFile.id}>
        <TableCell component="th" scope="row">
            {getLanguageFromId(translationFile.id, languages)}
        </TableCell>
        <TableCell align="right">{getLanguageFlagFromId(translationFile.id, languages)}</TableCell>
        <TableCell align="right">{getStatus(translationFile.doneCount, projectFile.totalKeys)}</TableCell>
        <TableCell align="right">{safePercent(translationFile.doneCount / projectFile.totalKeys)}</TableCell>
        <TableCell align="right">{`${doneCount}\\${projectFile.totalKeys}`}</TableCell>
        <TableCell align="right">
            <EditOutlined style={{ marginRight: '12px', cursor: 'pointer' }} onClick={() => {
                onEdit(project.id, projectFile.id, translationFile.id)
            }}/>
            <DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => {
                clearTranslationFile(projectFile.id, translationFile.id)
                setDoneCount(0)
            }}/>
        </TableCell>
    </TableRow>
}

function getStatus(done: number, total: number): string {
    if (done === total) {
        return 'done'
    }
    if (done === 0) {
        return 'not started'
    }
    return 'in progress'
}

function getLanguageFromId(id: number, languages: Record<number, Language>): string {
    if (languages[id]) return languages[id].displayName
    return ''
}

function getLanguageFlagFromId(id: number, languages: Record<number, Language>) {
    if (languages[id]) return <Flag code={mapIsoCode(languages[id].ISOCode)} height="16" width="24"/>
    return ''
}

export default function FileTable(props: { projectFile: ProjectFile, project: Project, open: boolean }) {
    const { projectFile, project, open } = props
    const classes = useStyles()
    const [languages, setLanguages] = React.useState<Record<number, Language>>([])
    const [isEditProjectFileModalOpen, setIsEditProjectFileModalOpen] = React.useState(false)
    const [isAdministrateKeysModalOpen, setIsAdministrateKeysModalOpen] = React.useState(false)
    const [dropdownOpen, setDropdownOpen] = React.useState<boolean>(open)
    useEffect(() => {
        setDropdownOpen(open)
    }, [open])

    function onEdit(projectId: number, projectFileId: number, languageId: number) {
        window.location.href = `/editTranslation?projectId=${projectId}&projectFileId=${projectFileId}&languageId=${languageId}`
    }

    if (Object.keys(languages).length === 0) {
        getAllLanguages().then(setLanguages)
    }

    function handleDeleteFile() {
        deleteFile(project.id, projectFile.id).then(() => window.location.reload())
    }

    return (
        <div>
            <h3>{projectFile.projectFileName}</h3>
            <EditProjectFileModal projectId={project.id} projectFile={projectFile} modalIsOpen={isEditProjectFileModalOpen} setIsOpen={setIsEditProjectFileModalOpen}/>
            <AdministrateKeysModal projectId={project.id} projectFile={projectFile} modalIsOpen={isAdministrateKeysModalOpen} setIsOpen={setIsAdministrateKeysModalOpen}/>
            <IconButton style={{ marginRight: '12px' }} aria-label="expand row" size="small" onClick={() => setDropdownOpen(!dropdownOpen)}>
                {dropdownOpen ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>}
            </IconButton>
            {canEditDeleteKeys(project.id) ? <Button style={{ marginRight: '15px' }} onClick={() => {
                setIsEditProjectFileModalOpen(true)
            }}>Edit</Button> : <></>}
            {canEditDeleteKeys(project.id) ? <Button style={{ marginRight: '15px' }} onClick={() => {
                setIsAdministrateKeysModalOpen(true)
            }}>Administrate Keys</Button> : <></>}
            {canEditDeleteKeys(project.id) ? <Button onClick={() => {
                handleDeleteFile()
            }}>Delete File</Button> : <></>}
            <Collapse in={dropdownOpen} timeout="auto" unmountOnExit>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Language</TableCell>
                                <TableCell align="right">Flag</TableCell>
                                <TableCell align="right">Status</TableCell>
                                <TableCell align="right">Percent</TableCell>
                                <TableCell align="right">Translations</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(projectFile.translationFilesExpanded).filter((translationFile) =>
                                canSeeLanguageForProject(project.id, translationFile.id))
                                .filter((translationFile) => {
                                    return !!languages[translationFile.id]
                                })
                                .map((translationFile: TranslationFile) =>
                                    <Row key={translationFile.id}
                                         translationFile={translationFile}
                                         projectFile={projectFile}
                                         project={project}
                                         languages={languages}
                                         onEdit={onEdit}/>)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Collapse>
        </div>
    )
}
