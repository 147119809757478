import React, {useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { EditOutlined } from '@material-ui/icons'
import { Project, createProject, ProjectFile, Language } from '@aag-translations/common/lib'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import { Button } from '@progress/kendo-react-buttons'
import FileTable from './FileTable'
import EditProjectModal from './EditProjectModal'
import { getAllProjects } from '../../services/ProjectService'
import { EditProjectFileModal } from './EditProjectFileModal'
import { canEditProjects } from '../../services/PermissionsService'
import { getAllLanguages } from '../../services/LanguageService'
import { getLogger } from '../../utils/logger'
import config from '../../config'

const logger = getLogger(config.logLevel)

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset'
        }
    }
})

let counter = 0

function Row(props: { project: Project; languages: Record<number, Language> }) {
    const { project, languages } = props
    const [open, setOpen] = React.useState<boolean>(false)
    const [childrenOpen, setChildrenOpen] = React.useState<boolean>(false)
    const [projectModalIsOpen, setProjectModalIsOpen] = React.useState<boolean>(false)
    const [projectFileModalIsOpen, setProjectFileModalIsOpen] = React.useState<boolean>(false)
    // const [languages, setLanguages] = React.useState<Record<number, Language>>([])

    // if (Object.keys(languages).length === 0) {
    //     logger.debug('Counter for allLanguages: ', ++counter)
    //     getAllLanguages(false).then(setLanguages)
    // }

    const classes = useRowStyles()
    return (
        <>
            <EditProjectModal project={project} modalIsOpen={projectModalIsOpen} setIsOpen={setProjectModalIsOpen} languages={languages} />
            {/*<EditProjectFileModal projectId={project.id} projectFile={null} modalIsOpen={projectFileModalIsOpen} setIsOpen={setProjectFileModalIsOpen} />*/}
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {project.projectName}
                </TableCell>
                <TableCell align="right">{Object.keys(project.projectFilesExpanded).length}</TableCell>
                <TableCell align="right">
                    {/*{canEditProjects() && (*/}
                    {/*    <EditOutlined*/}
                    {/*        style={{ cursor: 'pointer', marginRight: '12px' }}*/}
                    {/*        onClick={() => {*/}
                    {/*            setProjectModalIsOpen(true)*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*)}*/}
                    {/*{canEditProjects() && (*/}
                    {/*    <AddOutlinedIcon*/}
                    {/*        style={{ cursor: 'pointer' }}*/}
                    {/*        onClick={() => {*/}
                    {/*            setProjectFileModalIsOpen(true)*/}
                    {/*        }}*/}
                    {/*    />*/}
                    {/*)}*/}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Button onClick={() => setChildrenOpen(!childrenOpen)}>{childrenOpen ? 'Collapse all' : 'Expand all'}</Button>
                        {project.projectFilesExpanded.map((projectFile: ProjectFile) => {
                            return <FileTable key={projectFile.id} projectFile={projectFile} project={project} open={childrenOpen} />
                        })}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

export default function OverviewTable(): JSX.Element {
    const [modalIsOpen, setIsOpen] = React.useState<boolean>(false)
    const [projects, setProjects] = React.useState<Array<Project>>([])
    const [languages, setLanguages] = React.useState<Record<number, Language>>([])
    //logger.debug('All projects list: ', projects)
    if (projects.length === 0) {
        //getAllProjects().then(setProjects)
        getAllProjects().then((response) => {
            if (response.length === 0) {
                return setProjects([createProject()])
            }
            return setProjects(response)
        })
    }

    useEffect(() => {
        const initializeLanguagues = async () => {
            const allLanguages = await getAllLanguages()
            setLanguages(allLanguages)
        }
        //console.log('useEffect')
        initializeLanguagues()
        // if (Object.keys(languages).length === 0) {
        //     logger.debug('Counter for allLanguages: ', ++counter)
        //     getAllLanguages(false).then(setLanguages)
        // }
    }, [])

    useEffect(() => {
        // console.log(projects)
    }, [projects])

    //console.log('render')

    return (
        <div>
            {/*<EditProjectModal key={0} project={null} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />*/}
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Application</TableCell>
                            <TableCell align="right">Language Files</TableCell>
                            <TableCell align="right" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projects.map((project: Project) => (
                            <Row key={project.id} project={project} languages={languages} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
