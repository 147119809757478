import { Language } from '@aag-translations/common/lib'
import axios from 'axios'
import getPath from '../utils/Routes'
import config from '../config'

export async function getAllLanguages(forceRefresh = false): Promise<Record<number, Language>> {
    return (await axios.get(`${getPath()}?method=allLanguages`)).data
}

export async function deleteLanguage(languageId: number) {
    return (await axios.post(`${getPath()}?method=deleteLanguage&languageId=${languageId}`, {})).data
}

export async function saveLanguage(language: Language) {
    return (await axios.post(`${getPath()}?method=saveLanguage`, language)).data
}

export function mapIsoCode(flagCode: string) {
    switch (flagCode) {
        case 'en':
            return 'us'
        case 'zh':
            return 'cn'
        case 'ja':
            return 'jp'
        default:
    }
    return flagCode
}
