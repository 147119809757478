import React from 'react'
import { toast, ToastContainer as OriginalToastContainer } from 'react-toastify'

export const NOTIFICATION_POSITIONS = toast.POSITION
export const NOTIFICATION_TYPES = toast.TYPE

const DEFAULTS = {
    AUTOCLOSE: 5000
}

export const notify = ({
    message,
    type = NOTIFICATION_TYPES.SUCCESS,
    position = NOTIFICATION_POSITIONS.TOP_CENTER,
    autoClose = DEFAULTS.AUTOCLOSE,
    onOpen = () => {}, // on componentDidMount
    onClose = () => {} // on componentWillUnmount
}) => {
    toast(message, {
        position,
        autoClose,
        type,
        onOpen,
        onClose
    })
}

export const notifyOnSuccess = ({ message, ...props }) => {
    return notify({ message, type: NOTIFICATION_TYPES.SUCCESS, ...props })
}

export const notifyOnError = ({ message, ...props }) => {
    return notify({ message, type: NOTIFICATION_TYPES.ERROR, ...props })
}

export const notifyOnInfo = ({ message, ...props }) => {
    return notify({ message, type: NOTIFICATION_TYPES.INFO, ...props })
}

export const notifyOnWarning = ({ message, ...props }) => {
    return notify({ message, type: NOTIFICATION_TYPES.WARNING, ...props })
}

export const ToastContainer = (props) => {
    return <OriginalToastContainer {...props} position={NOTIFICATION_POSITIONS.TOP_CENTER} autoClose={DEFAULTS.AUTOCLOSE} />
}
