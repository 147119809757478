import { languageFileType } from '@aag-translations/common'
import { ProjectFile, TranslationFile } from '@aag-translations/common/lib'
import { getTranslationFile } from './TranslationFileService'
import { getAllLanguages } from './LanguageService'

const exporters: Record<languageFileType, Function> = {
    [languageFileType.apacheLanguageFile]: exportApache,
    [languageFileType.webDynpro]: exportWebDynpro,
    [languageFileType.inApp]: exportInApp,
    [languageFileType.appContent]: exportAppContent,
    [languageFileType.applicationLanguageFile]: exportAppContent, // same as app content
    [languageFileType.classificationLanguageFile]: exportClassification //Special case return objects of objects
}

export async function exportLanguageFile(projectFile: ProjectFile, translationFile: TranslationFile, exportType: languageFileType): Promise<string> {
    if (exportType === languageFileType.classificationLanguageFile) {
        return await exportClassification(projectFile)
    } else {
        return exporters[exportType](projectFile, translationFile)
    }
}

function exportApache(projectFile: ProjectFile, translationFile: TranslationFile): string {
    return Object.values(projectFile.translationKeys).map((projectFileKey) => {
        return `msgid "${projectFileKey.keyName}" \nmsgstr "${translationFile.translationsKeys[projectFileKey.id].translatedValue}"`
    }).join('\n')
}

function exportWebDynpro(projectFile: ProjectFile, translationFile: TranslationFile): string {
    return Object.values(projectFile.translationKeys).map((projectFileKey) => {
        return projectFileKey.keyName + '\t0\t' + translationFile.translationsKeys[projectFileKey.id].translatedValue
    }).join('\n')
}

function exportInApp(projectFile: ProjectFile, translationFile: TranslationFile): string {
    return Object.values(projectFile.translationKeys).map((projectFileKey) => {
        return `"${projectFileKey.keyName}"="${translationFile.translationsKeys[projectFileKey.id].translatedValue}";`
    }).join('\n')
}

function exportAppContent(projectFile: ProjectFile, translationFile: TranslationFile): string {
    return Object.values(projectFile.translationKeys).map((projectFileKey) => {
        return projectFileKey.keyName + '=' + translationFile.translationsKeys[projectFileKey.id].translatedValue
    }).join('\n')
}

async function exportClassification(projectFile: ProjectFile): Promise<string> {
    await Promise.all(Object.values(projectFile.translationFilesExpanded).map(async (translationFile) => {
        projectFile.translationFilesExpanded[translationFile.id] = await getTranslationFile(projectFile.id, translationFile.id, true)
    }))
    const languages = await getAllLanguages()
    let result = ''
    Object.values(projectFile.translationKeys).forEach((projectFileKey) => {
        Object.values(projectFile.translationFilesExpanded).forEach((translationFile) => {
            result += `${projectFileKey.keyName}\t${languages[translationFile.id].ISOCode}\t${translationFile.translationsKeys[projectFileKey.id].translatedValue}`
            result += `\n`
        })
    })
    return result
}