import React from 'react'
import styled from 'styled-components'
import { createNewUser, Language, Project, ProjectIndividualRights, ProjectRight, User, UserIndividualRights } from '@aag-translations/common/lib'
import { getAllLanguages, mapIsoCode } from '../../services/LanguageService'
import { Tab, Table, TableBody, TableCell, TableRow, Tabs, TextField } from '@material-ui/core'
import { Checkbox as KendoCheckbox } from '@progress/kendo-react-inputs'
import { getAllProjects } from '../../services/ProjectService'
import { GridWrapper, StyledPaper } from '../style/FormComponents'
import Flag from 'react-world-flags'
import { saveUser } from '../../services/UserService'
import { Dialog } from '@progress/kendo-react-dialogs'
import DialogActionButtons from '../style/DialogActionButtons'


const FlagWrapper = styled(Flag)`
`

const CheckboxWrapper = styled.div`
    margin-bottom: 15px;
`

const FlagCheckboxWrapperRoot = styled.div`
    width: 34px;
    height: 28px; 
    margin: 4px;
    width: 17%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background: rgb(235, 235, 235);
    padding: 3px 5px;
    border-radius: 4px;
`

const FlagTable = styled.div` 
`

const ViewContainer = styled.div`
    text-align: left;
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const StyledTextField = styled(TextField)`
    width: 46%;
    margin-bottom: 12px !important;
`

const StyledTableCell = styled(TableCell)`
    padding: 0px !important;
    display: flex !important; 
    flex-direction: column;
`

export function EditUserModal(props: { user: User | null; modalIsOpen: boolean; setIsOpen: Function }) {
    const { modalIsOpen, setIsOpen } = props
    let user = props.user as User
    if (!user) {
        user = createNewUser()
    }
    const [languages, setLanguages] = React.useState<Record<number, Language>>([])
    const [selectedWindow, setSelectedWindow] = React.useState<number>(0)
    const [projects, setProjects] = React.useState<Array<Project>>([])
    const [loaded, setIsLoaded] = React.useState(false)
    if (modalIsOpen) {
        if (projects.length === 0) getAllProjects().then(setProjects).then(
            () => {
                if (Object.keys(languages).length === 0) getAllLanguages().then(setLanguages).then(() => setIsLoaded(true))
            }
        )

    }


    function closeModal(): void {
        setIsOpen(false)
    }

    function save() {
        saveUser(user).then(closeModal)
    }

    function ApplicationRow(props: { projectId: number }) {
        const { projectId } = props
        if (!user.projectRights[projectId]) {
            user.projectRights[projectId] = new ProjectRight()
        }
        let projectRight: ProjectRight = user.projectRights[projectId]

        function setProjectRight(right: ProjectIndividualRights) {
            if (projectRight.individualRights[right]) {
                delete projectRight.individualRights[right]
            } else {
                projectRight.individualRights[right] = true
            }
        }

        function getCheckboxImage(code: string, value: boolean, onChange: Function) {
            let flagCode = mapIsoCode(code)
            return <FlagCheckboxWrapperRoot key={code}>
                <FlagWrapper style={{marginRight: '4px'}} height="16" width="24" code={flagCode}/>
                <div style={{marginRight: '5px'}}>{code}</div>
                <KendoCheckbox
                    defaultChecked={value}
                    onChange={() => {
                        onChange()
                    }}
                />
            </FlagCheckboxWrapperRoot>
        }

        function getFlagCheckBox(language: Language) {
            function changeChecked() {
                if (projectRight.languageRights[language.id]) {
                    delete projectRight.languageRights[language.id]
                } else {
                    projectRight.languageRights[language.id] = true
                }
            }

            return getCheckboxImage(language.ISOCode, projectRight.languageRights[language.id], changeChecked)
        }

        return <StyledTableCell>
            <StyledPaper style={{ padding: '0px' }}>
                <h3 style={{ width: '100%', paddingLeft: '15px' }}>{projects.find((project) => project.id === projectId)?.projectName}</h3>
                <div style={{ marginBottom: '15px', width: '100%', paddingLeft: '15px' }}>
                    <CheckboxWrapper>
                        <KendoCheckbox label="Edit, delete, administrate keys"
                                    defaultChecked={projectRight.individualRights[ProjectIndividualRights.EditDeleteKeys]}
                                    onChange={() => setProjectRight(ProjectIndividualRights.EditDeleteKeys)}/>
                    </CheckboxWrapper>
                    <CheckboxWrapper>
                        <KendoCheckbox label="Create language file"
                                    defaultChecked={projectRight.individualRights[ProjectIndividualRights.CreateFiles]}
                                    onChange={() => setProjectRight(ProjectIndividualRights.CreateFiles)}/>
                    </CheckboxWrapper>
                    <KendoCheckbox label="Upload language file"
                                defaultChecked={projectRight.individualRights[ProjectIndividualRights.UploadFiles]}
                                onChange={() => setProjectRight(ProjectIndividualRights.UploadFiles)}/>
                </div>
                <div style={{ padding: '6px' }}>
                    <FlagTable style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                        {Object.keys(languages).map((languageId) => {
                            let language = languages[languageId as unknown as number]
                            return getFlagCheckBox(language)
                        })}
                    </FlagTable>
                </div>
            </StyledPaper>
        </StyledTableCell>
    }

    function getApplicationsView() {
        return <ViewContainer>
            <Table aria-label="collapsible table">
                <TableBody>
                    {projects.map((project: Project) => (
                        <TableRow key={project.id}>
                            <ApplicationRow projectId={project.id}/>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </ViewContainer>
    }

    function getSettingsView() {
        function toggleUserRight(right: UserIndividualRights) {
            if (user.individualRights[right]) {
                delete user.individualRights[right]
            } else {
                user.individualRights[right] = true
            }
        }

        return <ViewContainer>
            <StyledPaper style={{ display: 'block' }}>
                <CheckboxWrapper>
                    <KendoCheckbox label="Full Administrative Rights"
                                   defaultChecked={user.individualRights[UserIndividualRights.FullAdministrative]}
                                   onChange={() => toggleUserRight(UserIndividualRights.FullAdministrative)}/>
                </CheckboxWrapper>
                <CheckboxWrapper>
                    <KendoCheckbox label="User"
                                   defaultChecked={user.individualRights[UserIndividualRights.User]}
                                   onChange={() => toggleUserRight(UserIndividualRights.User)}/>
                </CheckboxWrapper>
                <CheckboxWrapper>
                    <KendoCheckbox label="Applications"
                                   defaultChecked={user.individualRights[UserIndividualRights.Projects]}
                                   onChange={() => toggleUserRight(UserIndividualRights.Projects)}/>
                </CheckboxWrapper>
                <KendoCheckbox label="Languages"
                               defaultChecked={user.individualRights[UserIndividualRights.Languages]}
                               onChange={() => toggleUserRight(UserIndividualRights.Languages)}/>
            </StyledPaper>
        </ViewContainer>
    }

    function getLoginDataView() {
        return <ViewContainer>
            <StyledPaper>
                <StyledTextField defaultValue={user.firstName} onChange={(event) => {
                    user.firstName = event.target.value
                }} label="Firstname"/>
                <StyledTextField defaultValue={user.lastName} onChange={(event) => {
                    user.lastName = event.target.value
                }} label="Surname"/>
                <StyledTextField defaultValue={user.userName} onChange={(event) => {
                    user.userName = event.target.value
                }} label="Username"/>
                <StyledTextField defaultValue={user.email} onChange={(event) => {
                    user.email = event.target.value
                }} label="Email"/>
            </StyledPaper>
            <StyledPaper style={{ display: 'block' }}>
                <KendoCheckbox label="Active"
                               defaultChecked={user.active ? true : false} onChange={() => user.active = !user.active}/>
            </StyledPaper>
        </ViewContainer>
    }

    function getWindow(selected: number) {
        if (selected === 0) {
            return getLoginDataView()
        }
        if (selected === 1) {
            return getSettingsView()
        }
        if (selected === 2) {
            return getApplicationsView()
        }
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedWindow(newValue)
    }


    if (modalIsOpen && loaded) {
        return (
            <Dialog>
                <Tabs
                    style={{ marginBottom: '20px' }}
                    variant="fullWidth"
                    value={selectedWindow}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                >
                    <Tab label="Login Data"/>
                    <Tab label="Settings"/>
                    <Tab label="Applications"/>
                </Tabs>
                <GridWrapper style={{maxHeight: '70vh', overflow: 'scroll'}}>
                    {getWindow(selectedWindow)}
                </GridWrapper>
                <DialogActionButtons onSave={save} onCancel={closeModal}/>
            </Dialog>
        )
    } else return <></>
}
