import { createProjectFileKey, createTranslationKey, languageFileType, Project, ProjectFile, ProjectFileKey, TranslationFile } from '@aag-translations/common/lib'
import { saveProjectFile } from './ProjectFileService'
import { getTranslationFile, saveTranslationFile } from './TranslationFileService'
import { getAllLanguages } from './LanguageService'

const parsers: Record<languageFileType, Function> = {
    [languageFileType.apacheLanguageFile]: parseApache,
    [languageFileType.webDynpro]: parseWebDynpro,
    [languageFileType.inApp]: parseInApp,
    [languageFileType.appContent]: parseAppContent,
    [languageFileType.applicationLanguageFile]: parseAppContent, // same as app content
    [languageFileType.classificationLanguageFile]: parseClassification //Special case return objects of objects
}

export async function parseAndSaveProjectFile(file: File, project: Project, projectFile: ProjectFile, translationFile: TranslationFile, overWrite = false) {
    const reader = new FileReader()
    return new Promise((resolve)=>{
        reader.onloadend = async () => {
            const result = reader.result as string
            const b64 = result.replace(/^data:.+;base64,/, '')
            const fileString = atob(b64)
            if (projectFile.fileType === languageFileType.classificationLanguageFile) {
                const result = parsers[projectFile.fileType](fileString)
                Object.values(projectFile.translationFilesExpanded).map(async (tfTemp) => {
                    const allLanguages = await getAllLanguages()
                    tfTemp = await getTranslationFile(projectFile.id, tfTemp.id, true)
                    const resultFile = result[allLanguages[tfTemp.id].ISOCode]
                    await mergeAndSaveProjectFile(project, projectFile, tfTemp, resultFile, overWrite)
                    await mergeAndSaveTranslationFile(projectFile, tfTemp, resultFile, overWrite)
                })
                console.log(result)
            } else {
                const result = parsers[projectFile.fileType](fileString)
                await mergeAndSaveProjectFile(project, projectFile, translationFile, result, overWrite)
                await mergeAndSaveTranslationFile(projectFile, translationFile, result, overWrite)
                console.log(result)
            }
            resolve()
        }
        reader.readAsDataURL(file)
    })
}

async function mergeAndSaveProjectFile(project: Project, projectFile: ProjectFile, translationFile: TranslationFile, data: Record<string, string>, overWrite = false) {
    let maxId = Math.max(...(Object.values(projectFile.translationKeys).map(item => item.id)), 0)
    if (!overWrite) {
        Object.keys(data).forEach((key: string) => {
            const foundKey = Object.values(projectFile.translationKeys).find((item: ProjectFileKey) => item.keyName === key)
            if (!foundKey) {
                maxId++
                const projectFileKey = createProjectFileKey()
                projectFile.translationKeys[maxId] = projectFileKey
                projectFileKey.keyName = key
                projectFileKey.id = maxId
                const translationKey = createTranslationKey()
                translationFile.translationsKeys[maxId] = translationKey
                translationKey.parentKeyId = maxId
                translationKey.translatedValue = data[key]
            }
        })
        await saveTranslationFile(projectFile.id, translationFile).then(() => saveProjectFile(project.id, projectFile))
    }
}

async function mergeAndSaveTranslationFile(projectFile: ProjectFile, translationFile: TranslationFile, data: Record<string, string>, overWrite = false) {
    if (overWrite) {
        Object.values(projectFile.translationKeys).forEach((projectFileKey: ProjectFileKey) => {
            if (data[projectFileKey.keyName]) {
                translationFile.translationsKeys[projectFileKey.id].translatedValue = data[projectFileKey.keyName]
            }
        })
        console.log(translationFile)
        await saveTranslationFile(projectFile.id, translationFile)
    }
}

function parseAppContent(file: string): Record<string, string> {
    return file.split('\n').filter((item) => {
        return item[0] !== '#'
    }).reduce<Record<string, string>>((acc: Record<string, string>, value: string) => {
        const split = value.split('=')
        acc[split[0]] = split[1]
        return acc
    }, {})
}

function parseApache(file: string): Record<string, string> {
    return file.split(/(msgid\s*)/).reduce<Record<string, string>>((acc: Record<string, string>, value: string) => {
        const split = value.split(/(msgstr\s*)/)
        if (split[0] && split[2]) acc[split[0].replaceAll('"', '').replaceAll(/\s*\n*\s*/g, '')] = split[2].replaceAll('"', '').replaceAll('\n', '')
        return acc
    }, {})
}

function parseWebDynpro(file: string): Record<string, string> {
    return file.split('\n').reduce<Record<string, string>>((acc: Record<string, string>, value: string) => {
        const split = value.split('\t')
        acc[split[0]] = split[2]
        return acc
    }, {})
}

function parseInApp(file: string): Record<string, string> {
    return parseAppContent(file.replaceAll('"', '').replaceAll(';', '').replaceAll(' = ', '='))
}

function parseClassification(file: string) {
    const perLanguageData: Record<string, Record<string, string>> = {}
    file.split('\n').forEach((value: string) => {
        const split = value.split('\t')
        if (split.length >= 3) {
            const languageName = split[1]
            const key = split[0]
            if (!perLanguageData[languageName]) {
                perLanguageData[languageName] = {}
            }
            split.shift()
            split.shift()
            perLanguageData[languageName][key] = split.join('\t').replaceAll('\t\t', '')
        }
    })
    return perLanguageData
}
