import styled from 'styled-components'
import { Grid, Paper } from '@material-ui/core'

export const InnerGrid = styled(Grid)`
    /*border: 1px solid red;*/
`

export const StyledPaper = styled(Paper)`
    margin-bottom: 20px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
`

export const GridWrapper = styled.div`
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 4px;
`
