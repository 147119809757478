import React, { useState } from 'react'
import styled from 'styled-components'
import { Language, newObjectId, ProjectFile, ProjectFileKey } from '@aag-translations/common/lib'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { Button } from '@progress/kendo-react-buttons'
import { Input, Paper, Table, TableBody, TableContainer, TableHead } from '@material-ui/core'
import { Checkbox } from '@progress/kendo-react-inputs'
import { Dialog } from '@progress/kendo-react-dialogs'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { downloadBase64File, getDocument, saveDocument } from '../../services/DocumentService'
import { GridWrapper } from '../style/FormComponents'
import { getAllLanguages } from '../../services/LanguageService'
import { getProjectFile, saveProjectFile } from '../../services/ProjectFileService'
import DialogButtons from '../style/DialogActionButtons'

const Container = styled.div`
    max-height: 95%;
    overflow: auto;
`

function Row(props: { projectFileKey: ProjectFileKey, projectId: number, projectFileId: number, deleteKey: Function }) {
    const { projectFileKey, projectId, projectFileId, deleteKey } = props
    const [documentPath, setDocumentPath] = useState(projectFileKey.documentLink)

    function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
        const reader = new FileReader()
        const files = event.target.files as FileList
        const file = files[0]
        reader.onloadend = () => {
            const result = reader.result as string
            const b64 = result.replace(/^data:.+;base64,/, '')
            const path = `documents/${projectId}/${projectFileId}/${projectFileKey.id}/${file.name}`
            setDocumentPath(path)
            projectFileKey.documentLink = path
            saveDocument(b64, path)
        }
        reader.readAsDataURL(file)
    }

    const splitPath = documentPath.split('/')
    const documentName = splitPath[splitPath.length - 1]

    async function handleFileDownload() {
        const documentData = await getDocument(`documents/${projectId}/${projectFileId}/${projectFileKey.id}/${documentPath}`)
        downloadBase64File(documentData, documentName)
    }

    function handleFileDelete() {
        setDocumentPath('')
        projectFileKey.documentLink = ''
    }

    return <TableRow key={projectFileKey.id}>
        <TableCell component="th">{projectFileKey.keyName}</TableCell>
        <TableCell><Input defaultValue={projectFileKey.maxLen} onChange={(event) => {
            projectFileKey.maxLen = parseInt(event.target.value)
        }}/>
        </TableCell>
        <TableCell><Input style={{ width: '750px' }} defaultValue={projectFileKey.comment} onChange={(event) => {
            projectFileKey.comment = event.target.value
        }}/>
        </TableCell>
        <TableCell>{
            documentPath != '' ? <div><Button onClick={() => handleFileDownload()}>{documentName}</Button><DeleteOutlineOutlinedIcon onClick={() => {
                handleFileDelete()
            }}/></div> : <div>
                <input
                    id='fileUpload'
                    type='file'
                    style={{ display: 'none' }}
                    onChange={(event) => handleFileUpload(event)}
                />
                <Button style={{ padding: '0px' }}><label style={{ padding: '8px 16px', cursor: 'pointer' }} htmlFor="fileUpload">upload</label></Button>
            </div>
        }</TableCell>
        <TableCell>
            <span style={{ display: 'flex', justifyContent: 'center' }}>
                <Checkbox defaultChecked={projectFileKey.blocked} onChange={() => projectFileKey.blocked = !projectFileKey.blocked}/>
            </span>
        </TableCell>
        <TableCell>
            <span style={{ display: 'flex', justifyContent: 'center', marginTop: '5px', cursor: 'pointer' }}>
                <HighlightOffIcon onClick={() => {
                    deleteKey(projectFileKey.id)
                }}/>
            </span>
        </TableCell>
    </TableRow>
}


export function AdministrateKeysModal(props: { projectId: number, projectFile: ProjectFile; modalIsOpen: boolean; setIsOpen: Function }) {
    const { modalIsOpen, setIsOpen, projectId } = props
    const [languages, setLanguages] = React.useState<Record<number, Language>>([])
    const [projectFile, setProjectFile] = React.useState<ProjectFile>({ id: newObjectId, translationKeys: {} } as ProjectFile)

    if (modalIsOpen) {
        if (Object.keys(languages).length === 0) getAllLanguages().then(setLanguages)
        if (projectFile.id === newObjectId) getProjectFile(projectId, props.projectFile.id, true).then(setProjectFile)
    }

    function closeModal(): void {
        setIsOpen(false)
    }

    function save() {
        saveProjectFile(projectId, projectFile).then(closeModal)
    }

    function deleteKey(id: number) {
        const tempProj = { ...projectFile }
        delete tempProj.translationKeys[id]
        setProjectFile(tempProj)
    }

    if (modalIsOpen) {
        return <Dialog height={'75%'}>
            <GridWrapper style={{ height: '65vh' }}>
                <Container>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {['Key', 'Max characters', 'Comment', 'Document', 'Blocked', 'Delete'].map(heading => <TableCell>{heading}</TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.values(projectFile.translationKeys).map((item) => {
                                    return <Row key={item.id} projectFileKey={item} projectId={projectId} projectFileId={projectFile.id} deleteKey={deleteKey}/>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
            </GridWrapper>
            <DialogButtons onSave={save} onCancel={closeModal}/>
        </Dialog>
    } else {
        return <></>
    }
}
