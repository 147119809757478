import axios from 'axios'
import { getItem } from './localStorage'
import { notifyOnError, notifyOnWarning } from '../components/common/Toast'

axios.interceptors.request.use((config) => {
    const AUTH_TOKEN = getItem('token')
    if (AUTH_TOKEN) {
        config.headers.Authorization = AUTH_TOKEN
    }
    return config
})

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    notifyOnWarning({ message: 'Ihre Sitzung ist abgelaufen. Sie werden erneut angemeldet.' })
                    window.location.reload()
                    return error
                case 403:
                    notifyOnWarning({ message: 'Sie sind nicht berechtigt, diese Aktion auszuführen.' })
                    break
                default:
                    notifyOnError({ message: 'Leider ist ein Fehler aufgetreten. Versuchen Sie, die Seite neu zu laden.' })
            }
        }
        if (error.message && error.message === 'Network Error') {
            notifyOnError({ message: 'Ein Netzwerk-Problem ist aufgetreten. Eventuell besteht keine Verbindung zum Internet.' })
        }
        return Promise.reject(error)
    }
)

export default axios

/*import axios from 'axios'
import cookie from 'cookie'
import { notifyOnError, notifyOnWarning } from '../components/common/Toast'

axios.interceptors.request.use((config) => {
    const AUTH_TOKEN = cookie.parse(document.cookie)
    if (AUTH_TOKEN) {
        config.headers.TR_AUTH_SAML_TOKEN = AUTH_TOKEN.TR_AUTH_SAML_TOKEN
    }
    return config
})

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    notifyOnWarning({ message: 'Your session has expired. Please log in again' })
                    window.location.reload()
                    return error
                case 403:
                    notifyOnWarning({ message: 'You are not authorized to perform this action' })
                    break
                default:
                    notifyOnError({ message: 'An error has been encountered' })
            }
        }
        if (error.message && error.message === 'Network Error') {
            notifyOnError({ message: 'Network Error' })
        }
        return Promise.reject(error)
    }
)

export default axios
*/
