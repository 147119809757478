import React, { useState } from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { Project, User } from '@aag-translations/common/lib'
import { DeleteOutlined, EditOutlined } from '@material-ui/icons'
import styled from 'styled-components'
import { deleteUser, getAllUsers } from '../services/UserService'
import { EditUserModal } from '../components/common/EditUserModal'
import { canEditUsers } from '../services/PermissionsService'
import { getAllProjects } from '../services/ProjectService'

const Wrapper = styled.div`
    max-width: 50%;
`

function getApplicationsCount(user: User) {
    return Object.keys(user.projectRights).length
}

function getStatus(status: boolean) {
    return status ? 'Active' : 'Inactive'
}

function Row(props: { user: User }) {
    const { user } = props
    const [modalIsOpen, setIsOpen] = React.useState<boolean>(false)
    const [projects, setProjects] = useState<Array<Project>>([])
    if (projects.length === 0) getAllProjects().then(setProjects)
    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" sortDirection="asc">
                    {user.id}
                </TableCell>
                <TableCell component="th" scope="row">
                    {user.userName}
                </TableCell>
                <TableCell align="right">{getApplicationsCount(user)}</TableCell>
                <TableCell align="right">{getStatus(user.active)}</TableCell>
                <TableCell align="right">
                    <EditUserModal user={user} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
                    <EditOutlined
                        style={{ cursor: 'pointer', marginRight: '12px' }}
                        onClick={() => {
                            setIsOpen(true)
                        }}
                    />
                    <DeleteOutlined
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            deleteUser(user.id).then(() => {
                                window.location.reload()
                            })
                        }}
                    />
                </TableCell>
            </TableRow>
        </>
    )
}

export default function Users(): JSX.Element {
    const [users, setUsers] = useState<Array<User>>([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    if (!canEditUsers()) {
        return <h2>You dont have permission to edit users</h2>
    }
    if (users.length === 0) getAllUsers().then(setUsers)
    return (
        <div>
            <EditUserModal
                user={null}
                modalIsOpen={isModalOpen}
                setIsOpen={(value: boolean) => {
                    setIsModalOpen(value)
                    getAllUsers().then(setUsers)
                }}
            />
            <Wrapper>
                <Table aria-label="collapsible table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell key="id">ID</TableCell>
                            <TableCell key="username">Username</TableCell>
                            <TableCell align="right">Applications</TableCell>
                            <TableCell align="right">Status</TableCell>
                            <TableCell align="right" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user: User) => (
                            <Row key={user.userName} user={user} />
                        ))}
                    </TableBody>
                </Table>
            </Wrapper>
        </div>
    )
}
