import axios from 'axios'
import { Project } from '@aag-translations/common/lib'
import getPath from '../utils/Routes'
import { getLogger } from '../utils/logger'
import config from '../config'

const logger = getLogger(config.logLevel)

export async function saveProject(project: Project) {
    return axios.post(`${getPath()}?method=saveProject`, project)
}

export async function getAllProjects(): Promise<Array<Project>> {
    const result = (await axios.get(`${getPath()}?method=allProjects`)).data
    logger.debug('Type of getAllProjects(): ', typeof result)
    return result
}

export async function getProject(projectId: number): Promise<Project> {
    return (await axios.get(`${getPath()}?method=project&projectId=${projectId}`)).data
}

export async function deleteProject(projectId: number): Promise<Project> {
    return (await axios.post(`${getPath()}?method=deleteProject&projectId=${projectId}`, {})).data
}
