import React from 'react'
import { Dialog } from '@progress/kendo-react-dialogs'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { createProjectFile, Language, languageFileType, ProjectFile } from '@aag-translations/common'
import { getAllLanguages } from '../../services/LanguageService'
import { saveProjectFile } from '../../services/ProjectFileService'
import { GridWrapper, StyledPaper } from '../style/FormComponents'
import { Checkbox } from '@progress/kendo-react-inputs'
import DialogButtons from '../style/DialogActionButtons'
import { ComboBox } from '@progress/kendo-react-dropdowns'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 280px;
`

export function EditProjectFileModal(props: { projectId: number, projectFile: ProjectFile | null; modalIsOpen: boolean; setIsOpen: Function }) {
    let projectFile: ProjectFile
    if (!props.projectFile) {
        projectFile = createProjectFile()
    } else {
        projectFile = props.projectFile
    }
    const { modalIsOpen, setIsOpen, projectId } = props
    const [languages, setLanguages] = React.useState<Record<number, Language>>([])
    const [fileType, setFileType] = React.useState<languageFileType>(projectFile.fileType)
    if (Object.keys(languages).length === 0) getAllLanguages().then(setLanguages)

    function closeModal(): void {
        setIsOpen(false)
    }

    function save() {
        saveProjectFile(projectId, projectFile).then(closeModal)
    }

    function changeFileType(fileTypeEvent: any) {
        let localFileType: languageFileType = fileType
        switch (fileTypeEvent.target.value) {
            case 'Application Language File':
                localFileType = languageFileType.applicationLanguageFile
                break
            case 'Classification Language File':
                localFileType = languageFileType.classificationLanguageFile
                break
            case 'Apache Language File':
                localFileType = languageFileType.apacheLanguageFile
                break
            case 'Web Dynpro':
                localFileType = languageFileType.webDynpro
                break
            case 'App (In client)':
                localFileType = languageFileType.inApp
                break
            case 'App (content)':
                localFileType = languageFileType.appContent
                break
        }
        console.log(localFileType)
        projectFile.fileType = localFileType
        setFileType(localFileType)
    }

    const names = ['','','','','','']
    names[languageFileType.applicationLanguageFile] = "Application Language File"
    names[languageFileType.classificationLanguageFile] = 'Classification Language File'
    names[languageFileType.apacheLanguageFile] =  'Apache Language File'
    names[languageFileType.webDynpro] =  'Web Dynpro'
    names[languageFileType.inApp] = 'App (In client)'
    names[languageFileType.appContent] = 'App (content)'

    if (modalIsOpen) {
        return <Dialog>
            <GridWrapper>
                <Container>
                    <StyledPaper style={{ display: 'bblock' }}>
                        <TextField
                            style={{ width: '100%' }}
                            label="Name"
                            defaultValue={projectFile.projectFileName}
                            onChange={(value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                projectFile.projectFileName = value.target.value
                            }}
                        />
                        <TextField
                            style={{ width: '100%' }}
                            type="number"
                            label="Max key length"
                            defaultValue={projectFile.maxKeyLen}
                            onChange={(value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                projectFile.maxKeyLen = parseInt(value.target.value)
                            }}
                        />

                    </StyledPaper>
                    <StyledPaper style={{ display: 'block' }}>
                        <Checkbox label="Unique value rule"
                                  defaultChecked={projectFile.isUniqueValue}
                                  onChange={() => {
                                      projectFile.isUniqueValue = !projectFile.isUniqueValue
                                  }}/>

                    </StyledPaper>
                    <ComboBox onChange={changeFileType} data={names} defaultValue={names[fileType]}/>
                    <DialogButtons onSave={save} onCancel={closeModal}/>
                </Container>
            </GridWrapper>
        </Dialog>
    } else {
        return <></>
    }

}
